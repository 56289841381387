import PropTypes from "prop-types";
import React from "react";
import { siteinfos } from "../cms/data/settings";
import Navbar, { NavbarRaw } from "./Navbar";
import Footer, { FooterRaw } from "./Footer";
import { MaintenanceContent } from "../pages/maintenance";
import CookiesBanner from "./CookiesBanner";

const Layout = ({ children, theme, raw, ...props }) => {
  raw = raw || false;
  const menusSettings = require("../cms/data/settings").menus;
  const footerSettings = require("../cms/data/settings").footer;
  if (raw || process.env.NODE_ENV === "development") {
    return (
      <>
        {!siteinfos.isInMaintenanceMode && (
          <NavbarRaw menusSettings={menusSettings} theme={theme} />
        )}
        {children}
        {!siteinfos.isInMaintenanceMode && (
          <FooterRaw menusSettings={menusSettings} footerSettings={footerSettings} theme={theme} />
        )}
        <CookiesBanner siteinfos={siteinfos} bypass={raw} />
      </>
    );
  }
  return (
    <>
      {!siteinfos.isInMaintenanceMode && (
        <>
          <Navbar />
          {children}
          <FooterRaw menusSettings={menusSettings} footerSettings={footerSettings} theme={theme} />
        </>
      )}
      {siteinfos.isInMaintenanceMode && <MaintenanceContent />}
      <CookiesBanner siteinfos={siteinfos} bypass={siteinfos.isInMaintenanceMode} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  raw: PropTypes.bool,
  theme: PropTypes.any
};

export default Layout;
