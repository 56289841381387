import PropTypes from "prop-types";
import React from "react";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import {Link as AnchorLink} from "react-scroll"; 
import { Link as RebassLink } from "rebass/styled-components";
import { Link as GatsbyLink } from "gatsby";
import { AnchorLink as GatstbyAnchorLink } from "gatsby-plugin-anchor-links";

const Link = ({ href, external, children, activeClassName, ...props }) => {
  href = href || "";
  const isAnchor = href.includes("#");
  const isExternal = href.startsWith("http") || external;
  if (isAnchor) {
    if(href.startsWith("#")){
      return(
        // <AnchorLink href={href} offset='60' {...props}>{children}</AnchorLink>
        <AnchorLink to={href.replace('#', '')} activeClass={activeClassName} spy={true} smooth={true} duration={600} offset={-60}>{children}</AnchorLink>
      )
    }
    return (
      <GatstbyAnchorLink to={href} {...props} activeClassName={activeClassName}>
        {children}
      </GatstbyAnchorLink>
    );
  } else if (!isExternal) {
    if (href === "/") {
      return (
        <GatsbyLink to={href} {...props} activeClassName={activeClassName}>
          {children}
        </GatsbyLink>
      );
    } else {
      return (
        <GatsbyLink to={href} {...props} activeClassName={activeClassName} getProps={({ isPartiallyCurrent }) => isPartiallyCurrent ? { className: "active" } : null}>
          {children}
        </GatsbyLink>
      );
    }
  }
  return (
    <RebassLink href={href} {...props}>
      {children}
    </RebassLink>
  );
};

Link.propTypes = {
  activeClassName: PropTypes.any,
  children: PropTypes.any,
  external: PropTypes.any,
  href: PropTypes.any
};
export default Link;
