import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Container from "./UI/Container";
import { motion, AnimatePresence } from "framer-motion";
import Markdown from "./UI/Markdown";
import Helmet from "react-helmet";

function getCookie(cname, defaultValue) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return defaultValue;
}
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const CookiesBanner = ({ siteinfos, bypass }) => {
  bypass = bypass || false;
  const [onboarding, setOnboarding] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined" && !bypass) {
      setOnboarding(getCookie("onboarding", false));
      setLoaded(true);
      if (getCookie("onboarding", false)) {
        PageView();
      }
    }
  }, []);
  const cookieAccept = () => {
    // action accept cookies
    setCookie("onboarding", !onboarding, 365);
    setOnboarding(!onboarding);
    PageView();
  };

  const PageView = () => {
    if (siteinfos.gacode) {
      if (siteinfos.gaviagtm) {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());

        gtag("config", siteinfos.gacode);
      } else {
        const ReactGA = require("react-ga");
        ReactGA.initialize(siteinfos.gacode, { debug: process.env.NODE_ENV === "development" });
        ReactGA.pageview(window.location.pathname);
      }
    }
    if (siteinfos.facebookPixel) {
      const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: process.env.NODE_ENV === "development" // enable logs
      };
      const ReactPixel = require("react-facebook-pixel");
      ReactPixel.init(siteinfos.facebookPixel, advancedMatching, options);
      ReactPixel.pageView();
    }
  };
  if (bypass) {
    return <></>;
  }
  const hasGA = siteinfos.gacode ? true : false;
  return (
    <Box variant="cookiesBanner">
      {hasGA && siteinfos.gaviagtm && (
        <Helmet>
          <script
            async
            src={"https://www.googletagmanager.com/gtag/js?id=" + siteinfos.gacode}
          ></script>
        </Helmet>
      )}
      <AnimatePresence initial={false}>
        {!onboarding && loaded && (siteinfos.gacode || siteinfos.facebookPixel) && (
          <motion.div
            positionTransition
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50, transition: { duration: 0.2 } }}
          >
            <Box bg="white">
              <Container>
                <Flex alignItems="center" py={2}>
                  <Markdown pr={2}>{siteinfos.rgpdBanner}</Markdown>
                  <Box
                    pl={5}
                    sx={{
                      flex: 1,
                      minWidth: "auto"
                    }}
                  >
                    <Button variant="primary" onClick={() => cookieAccept()}>
                      J&#39;accepte
                    </Button>
                  </Box>
                </Flex>
              </Container>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

CookiesBanner.propTypes = {
  bypass: PropTypes.bool,
  siteinfos: PropTypes.shape({
    facebookPixel: PropTypes.any,
    gacode: PropTypes.any,
    rgpdBanner: PropTypes.any,
    gaviagtm: PropTypes.bool
  })
};

export default CookiesBanner;
