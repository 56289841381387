import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "./UI/Markdown";
import Fontello from "./UI/Fontello";
import CustomImage from "./UI/CustomImage";

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";


  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Box bg="secondary" pt={[7,7,8]}>
      <Container 
      sx={{
        overflow: "hidden",
        color: "muted",
        "a:hover": {
          color: "white"
        }
      }}>
        <Flex variant="grid" justifyContent="space-between" flexDirection={["column","row"]}>
          <Box sx={{flex: ["0 0 100%","0 0 100%","inherit"]}}>
            <Box pt={6}>
              <CustomImage height="40px" width="auto" img={footerSettings.logoFooter} />
            </Box>
          </Box>
          {footerSettings.addressFooter && (
            <Box>
              <Box variant="titleFooter" pt={6}><Text><Fontello icon="location" className="icon-big icon-left" />Adresse</Text></Box>
              <Markdown>{footerSettings.addressFooter}</Markdown>
            </Box>
          )}
          <Box>
            {footerSettings.mailFooter && (
              <Box>
                <Box variant="titleFooter" pt={6}><Text><Fontello icon="mail" className="icon-big icon-left" />Email</Text></Box>
                <Markdown>{footerSettings.mailFooter}</Markdown>
              </Box>
            )}
            {footerSettings.phoneFooter && (
              <Box>
                <Box variant="titleFooter" pt={6}><Text><Fontello icon="phone" className="icon-big icon-left" />Téléphone</Text></Box>
                <Markdown>{footerSettings.phoneFooter}</Markdown>
              </Box>
            )}
          </Box>
          <Box>
            <Box variant="titleFooter" pt={6}><Text>{footerSettings.cnapsFooter.titre}</Text></Box>
            <Markdown>{footerSettings.cnapsFooter.text}</Markdown>
            <CustomImage height="auto" width="250px" img={footerSettings.cnapsFooter.image} />
          </Box>
        </Flex>
        <Flex 
        justifyContent="space-between"
        fontSize={0}
        py={8}>
          <Box pt={6}>
            <Flex flexDirection={["column","row"]}>
              {menusSettings.footermenu.map((item, i) => (
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                    key={"menu-" + i}
                  >
                    <Button variant="ninja"
                    mr={6}>
                      <Text mb={[3,0]}>{item.title}</Text>
                    </Button>
                  </Link>
              ))}
            </Flex>
          </Box>
          <Box pt={6}>
            <>
              Réalisation : <a href="https://la-fabrik.nc"><strong>La Fabrik</strong></a>
            </>
          </Box>
        </Flex>  
      </Container>
    </Box>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    footermenu: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { in: ["footer","menus"] } }) {
          frontmatter {
            activeTopMenu
            activeFooter
            footermenu {
              offset
              path
              title
            }
            logoFooter {
              publicURL
              childImageSharp {
                fixed(height: 64, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            addressFooter
            phoneFooter
            mailFooter
            cnapsFooter {
              titre
              text
              image {
                publicURL
                childImageSharp {
                  fixed(height: 120, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const menusSettings = markdownRemark.frontmatter || {};
  const footerSettings = markdownRemark.frontmatter || {};

  return (
    <FooterRaw theme={theme} menusSettings={menusSettings} footerSettings={footerSettings} logoAlt={site.siteMetadata.title} />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
