import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Icofont from "./UI/Icofont";
import Sticky from "react-stickynode";
import Drawer from "./Drawer";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "./UI/CustomImage";

const NavbarRaw = ({ theme, menusSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  menusSettings.logo = typeof menusSettings.logo==="string"?menusSettings.logo:menusSettings.logo.publicURL;
  menusSettings.logo2 = typeof menusSettings.logo2==="string"?menusSettings.logo2:menusSettings.logo2.publicURL;
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Sticky
      top={0}
      innerZ={10}
      onStateChange={status => setStiked(status.status === Sticky.STATUS_FIXED)}
    >
      <Box variant="navbar" className={stiked ? "sticky" : "sticky-off"}>
        <Container>
          <Flex alignItems="center">
            <Box height={"100px"} py={4}>
              <Link href="/">
                <CustomImage                  
                  height="100%"
                  width="auto"
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                  id={stiked ? "logo" : "logo-white"}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box alignItems="center" display={["none", "none", "none", "flex"]}>
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Box 
                  ml={[0, 0, 0, 10]}
                  variant="mainMenuItem"
                  key={"menu-" + i}>
                    <Link
                      activeClassName="active"
                      href={item.path}
                      external={item.external}
                    >
                      <Button variant="ninja">{item.title}</Button>
                    </Link>
                   </Box>
                ))}
            </Box>
            <Drawer
              closeButton={
                <Box textAlign="right">
                  <Button variant="ninja" p={3}>
                    <Icofont icon="close" size={2} />
                  </Button>
                </Box>
              }
              placement="right"
              drawerHandler={
                <Button variant="ninja" pl={2} display={["block", "block", "block", "none"]}>
                  <Icofont icon="navigation-menu" size={2} />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box
              width={["100vw", "100vw", "100vw", "400px"]} 
              bg="background"
              display="flex"
              flexDirection="column"
              alignItems="center">
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                  <Box variant="mainMenuItem" key={i}>
                    <Link href={item.path} external={item.external} onClick={toggleHandler} activeClassName="active">
                      <Button
                        variant="ninja"
                        px={3}
                        py={3}
                        pl={[0, 0, 3]}
                        width="100%"
                        textAlign={["center", "center", "center", "left"]}
                        onClick={toggleHandler}
                      >{item.title}</Button>
                    </Link>
                  </Box>
                  ))}
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Sticky>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { eq: "menus" } }) {
          frontmatter {
            activeTopMenu
            logo {
              publicURL
              childImageSharp {
                fixed(height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            logo2 {
              publicURL
              childImageSharp {
                fixed(height: 40, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            mainmenu {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
          }
        }
      }
    `
  );

  const menusSettings = markdownRemark.frontmatter || {};
  return (
    <NavbarRaw theme={theme} menusSettings={menusSettings} logoAlt={site.siteMetadata.title} />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default withTheme(Navbar);
